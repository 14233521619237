import React, { useEffect } from 'react';
import GoogleButton from 'react-google-button';
import { useNavigate, Link } from 'react-router-dom';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';
import useAuth from '../hooks/useAuth';
import myImage from '../assets/logo512.png';
import backgroundImage from '../assets/bg.jpg';

function Landing() {
  const { signInWithGoogle, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user]);

  return (
    <div>
      <HeaderComponent />
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed', // This creates the parallax effect
          minHeight: '60vh',
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <img src={myImage} alt="Art Showcase" className="img-fluid" style={{ maxWidth: '200px' }} />
      </div>
      <main className="container my-5">
        <section className="text-center d-flex flex-column align-items-center justify-content-center my-5">
          <h1>For the Exclusive, the Rare, & the Unique</h1>
          <p>Caset is the marketplace for video shorts and music singles</p>
          <GoogleButton onClick={signInWithGoogle} className="mt-3" />
          <p className="mt-3 small">
            By using Caset, you agree to our
            {' '}
            <Link to="/terms">Terms and Conditions</Link>
            .
          </p>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
}

export default Landing;
