import React from 'react';
import { NavLink } from 'react-router-dom';

function FooterComponent() {
  return (
    <footer className="bg-dark">
      <div className="container text-center">
        <div className="mb-2">
          <a href="https://principal.la" target="_blank" rel="noopener noreferrer" className="text-light small">
            © 2024 Principal LA, LLC
          </a>
        </div>
        <div>
          <NavLink to="/terms" className="text-light me-2 small">Terms and Conditions</NavLink>
          <NavLink to="/privacy" className="text-light small">Privacy Policy</NavLink>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
