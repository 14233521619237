import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../utils/firebase';

export const googleProvider = new GoogleAuthProvider();

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isArtist, setIsArtist] = useState(false);
  const [userPrivateData, setUserPrivateData] = useState(null);

  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/');
      setIsArtist(false);
      setUserPrivateData(null);
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const signInWithGoogle = () => signInWithPopup(auth, googleProvider)
    .then(() => {
      console.log('Google sign-in OK');
    })
    .catch((error) => {
      console.log('Error during Google sign-in:', error);
      throw error;
    });

  const fetchUserData = async (userId) => {
    try {
      const docRef = doc(db, 'userPrivate', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserPrivateData(docSnap.data());
      } else {
        console.log('No private data found for user');
      }

      const docPublicRef = doc(db, 'users', userId);
      const docPublicSnap = await getDoc(docPublicRef);

      if (docPublicSnap.exists()) {
        const userPublicData = docPublicSnap.data();
        if (userPublicData) {
          const artistFlag = userPublicData.roles && userPublicData.roles.includes('artist');
          setIsArtist(artistFlag);
        }
      } else {
        console.log('No private data found for user');
      }
    } catch (error) {
      console.error('Error fetching user private data:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (loggedUser) => {
      if (loggedUser) {
        setUser(loggedUser);
        fetchUserData(loggedUser.uid); // Fetch private data
      } else {
        setUser(null);
        setIsArtist(false);
        setUserPrivateData(null); // Clear private data
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return {
    user, isArtist, userPrivateData, handleLogout, signInWithGoogle,
  };
};

export default useAuth;
