import React from 'react';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';

function Privacy() {
  return (
    <div>
      <HeaderComponent />
      <main className="container my-5">
        <section className="my-5">
          <h1 className="display-4 mb-4 text-light text-center">
            Privacy Policy
          </h1>
          <div className="text-light">
            <p>Last updated: January 11, 2024</p>

            <h2>Introduction</h2>
            <p>Welcome to Caset. This Privacy Policy explains how we collect, use, and share your information. By using Caset, you agree to the collection and use of information in accordance with this policy.</p>

            <h2>Information Collection and Use</h2>
            <p>We collect various types of information for various purposes to provide and improve our service to you.</p>

            <h3>Types of Data Collected</h3>
            <ul>
              <li>
                <strong>Personal Data:</strong>
                {' '}
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you.
              </li>
              <li>
                <strong>Usage Data:</strong>
                {' '}
                We may also collect information on how the Service is accessed and used.
              </li>
              <li>
                <strong>Tracking & Cookies Data:</strong>
                {' '}
                We use cookies and similar tracking technologies to track activity on our Service and hold certain information.
              </li>
            </ul>

            <h2>Use of Data</h2>
            <p>Caset uses the collected data for various purposes including to provide and maintain the Service, notify you about changes to our Service, provide customer care and support, and monitor the usage of the Service.</p>

            <h2>Transfer of Data</h2>
            <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>

            <h2>Intellectual Property Rights Protection</h2>
            <p>
              At Caset, we respect the intellectual property rights of others and expect our users to do the same. Users may upload copyrighted material to our platform, which we will do our best to protect and distribute exclusively to those users who have made a purchase. While we take reasonable steps to safeguard such material, we cannot control or prevent the ripping of audio or video content by users.
            </p>
            <p>
              It&apos;s important to note that downloading of any material is strictly prohibited as mentioned in our Terms of Service. We continually strive to implement measures that protect the rights of content creators and copyright holders.
            </p>

            <h2>Disclosure of Data</h2>
            <p>Caset may disclose your Personal Data in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend the rights or property of Caset, prevent or investigate possible wrongdoing in connection with the Service, protect the personal safety of users of the Service or the public, and protect against legal liability.</p>

            <h2>Security of Data</h2>
            <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

            <h2>Service Providers</h2>
            <p>We may employ third-party companies and individuals to facilitate our Service, provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

            <h2>Links to Other Sites</h2>
            <p>Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party&apos;s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us by email: support@caset.co.</p>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
}

export default Privacy;
