import React from 'react';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';

function TermsOfService() {
  return (
    <div>
      <HeaderComponent />
      <main className="container my-5">
        <section className="my-5">
          <h1 className="display-4 mb-4 text-light text-center">
            Terms of Service
          </h1>
          <div className="text-light">
            <p>Last updated: January 11, 2024</p>

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing and using Caset, you agree to comply with and be bound by these Terms of Service.</p>

            <h2>2. User Accounts</h2>
            <p>Users must be 18 years or older to create an account. Accounts for individuals under the age of 18 are not permitted.</p>

            <h2>3. Content and Copyright</h2>
            <p>
              Artists are solely responsible for the content they upload to Caset. They must ensure they hold the copyright for any such content. In the event of a legal dispute or copyright infringement claim, the artist&apos;s contact details will be provided to the appropriate parties for resolution. Caset reserves the right to remove any content that violates copyright laws or our community standards.
            </p>

            <h2>4. Payments and Payouts</h2>
            <p>Artists are paid through Stripe. Caset pays out 70% of each transaction to the artist. Payout schedules are managed by Stripe. We do not store credit card or banking information.</p>

            <h2>5. DMCA Compliance</h2>
            <p>Caset is registered under the DMCA with registered ID #121415. For copyright concerns, please contact support@caset.co.</p>

            <h2>6. Prohibited Content</h2>
            <p>We do not allow sexually explicit images, videos, or content that is overtly vulgar or illegal in California.</p>

            <h2>7. Editorial Approval</h2>
            <p>All Casets must be approved by our editorial staff before they are made live on the platform.</p>

            <h2>8. No Refund Policy</h2>
            <p>Caset does not provide refunds under any circumstances, including the removal of a Caset or content that a purchaser does not like or agree with.</p>

            <h2>9. Content Availability</h2>
            <p>Content may change at any time, including being removed. Purchasing content grants access only for the duration the Caset is live and approved. Artists or Caset may remove content at any time.</p>

            <h2>10. Limitations on Use</h2>
            <p>Content purchased on Caset is for streaming only. Downloading content is strictly prohibited.</p>

            <h2>11. Changes to Terms</h2>
            <p>We reserve the right to modify these Terms of Service at any time. Your continued use of Caset after any such changes constitutes your acceptance of the new Terms.</p>

            <h2>12. Contact Us</h2>
            <p>For any questions or concerns about these Terms of Service, please contact us at support@caset.co.</p>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
}

export default TermsOfService;
