import React from 'react';
import { NavLink } from 'react-router-dom';
import useAuth from '../hooks/useAuth'; // Adjust the import path as needed
import logo from '../assets/logo512.png'; // Import the logo

function HeaderComponent() {
  const { user, isArtist, handleLogout } = useAuth();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <NavLink to="/" className="navbar-brand">
          <img src={logo} alt="Caset Logo" style={{ height: '30px' }} />
        </NavLink>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            {user
              && (
              <>
                <li className="nav-item">
                  <NavLink to="/home" className="nav-link">Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/account" className="nav-link">Account</NavLink>
                </li>
                {isArtist
                  && (
                  <li className="nav-item">
                    <NavLink to="/edit" className="nav-link">Create</NavLink>
                  </li>
                  )}
              </>
              )}
          </ul>
          {user && (
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default navigation behavior
                    handleLogout(); // Call your logout function
                  }}
                >
                  Logout
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}

export default HeaderComponent;
