/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  doc, getDoc, collection, setDoc,
} from 'firebase/firestore';
import {
  getStorage, ref, uploadBytesResumable, getDownloadURL,
} from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../utils/firebase';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';
import useAuth from '../hooks/useAuth';

function EditCaset() {
  const { user } = useAuth();
  const [casetData, setCasetData] = useState({
    title: '',
    description: '',
    price: '$1.99',
    image: null,
    video: null,
  });
  const { casetId } = useParams();
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  useEffect(() => {
    if (!casetId) {
      setCasetData({
        title: '',
        description: '',
        price: 0,
        image: null,
        video: null,
      });
      setImagePreviewUrl(null);
      setVideoPreviewUrl(null);
      return; // Return early if casetId is not defined
    }

    const fetchCaset = async () => {
      const casetRef = doc(db, 'caset', casetId);
      const casetPrivateRef = doc(db, 'casetPrivate', casetId);

      try {
        const casetSnap = await getDoc(casetRef);
        const casetPrivateSnap = await getDoc(casetPrivateRef);

        if (casetSnap.exists() && casetPrivateSnap.exists()) {
          const tmpCasetData = casetSnap.data();
          const casetPrivateData = casetPrivateSnap.data();

          setCasetData({
            title: tmpCasetData.title,
            description: tmpCasetData.description,
            price: tmpCasetData.price,
            image: null, // Reset to null
            video: null, // Reset to null
          });

          setImagePreviewUrl(tmpCasetData.previewImage || null);
          setVideoPreviewUrl(casetPrivateData.internalContentLink || null);
        } else {
          console.error('Error fetching caset:', 'error');
          // navigate('/');
        }
      } catch (error) {
        console.error('Error fetching caset:', error);
        // navigate('/');
      }
    };

    fetchCaset();
  }, [casetId, navigate]);

  const handleArchive = async () => {
    // Call Firebase Function to Archive
    try {
      const functions = getFunctions();
      const archiveCasetFunction = httpsCallable(functions, 'archiveCaset');
      await archiveCasetFunction({ casetId });
      toast.success('Caset archived successfully');
      navigate('/home'); // or wherever you want to redirect after archiving
    } catch (error) {
      console.error('Error archiving caset:', error);
      toast.error('Failed to archive caset');
    }
    setShowArchiveModal(false);
  };

  const uploadFileToStorage = (file, path) => new Promise((resolve, reject) => {
    const storage = getStorage();
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Get upload progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        reject(error);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // Resolve with both the download URL and the internal storage path
          resolve({
            downloadURL,
            internalPath: path,
          });
        });
      },
    );
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      console.error('User must be authenticated to edit or create a caset.');
      return;
    }

    if (!casetData.title || !casetData.description || !casetData.price) {
      console.error('Title, description, and price fields are required');
      return; // Prevent form submission if any required field is missing
    }

    // For new caset, check if file objects are present in image and video fields
    if (!casetId) {
      if (!(casetData.image instanceof File) || !(casetData.video instanceof File)) {
        toast.error('Please upload both a preview image and content file');
        return;
      }
    }

    setUploading(true);

    try {
      let casetRef;
      let casetPrivateRef;

      if (casetId) {
        // Editing existing caset
        casetRef = doc(db, 'caset', casetId);
        casetPrivateRef = doc(db, 'casetPrivate', casetId);
      } else {
        // Creating new caset
        casetRef = doc(collection(db, 'caset'));
        casetPrivateRef = doc(db, 'casetPrivate', casetRef.id);
      }

      let previewImageUrl = casetData.previewImage;
      let videoInternalUrl;

      // Upload new image if it's a different file
      if (!casetId || (casetData.image && casetData.image instanceof File)) {
        const uploadResult = await uploadFileToStorage(casetData.image, `previewImages/${user.uid}/${casetRef.id}`);
        previewImageUrl = uploadResult.downloadURL; // Use the download URL for the preview image
      }

      // Upload new video if it's a different file
      if (!casetId || (casetData.video && casetData.video instanceof File)) {
        const uploadResult = await uploadFileToStorage(casetData.video, `videos/${user.uid}/${casetRef.id}`);
        videoInternalUrl = uploadResult.internalPath; // Use the internal path for the video
      }

      // Update or create the public Caset document
      const casetUpdateData = {
        title: casetData.title,
        description: casetData.description,
        price: casetData.price,
        previewImage: previewImageUrl,
        status: 'pending',
      };

      if (!casetId) {
        casetUpdateData.artistId = user.uid;
        casetUpdateData.viewCount = 0;
        casetUpdateData.statusReason = '';
      }

      await setDoc(casetRef, casetUpdateData, { merge: true });

      // Update the private Caset document
      if (videoInternalUrl) {
        await setDoc(casetPrivateRef, { internalContentLink: videoInternalUrl }, { merge: true });
      }

      navigate('/home'); // Redirect after editing or creation
    } catch (error) {
      console.error('Error updating or creating caset:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleChange = (e) => {
    const {
      name, value, type, files,
    } = e.target;

    if ((type === 'file') && (files.length > 0)) {
      if (files[0].size > 1024 * 1024 * 1024) {
        toast.error('File size should be 1 GB or less.');
        return; // Prevent file from being set if it's too large
      }

      setCasetData({ ...casetData, [name]: files[0] });

      // Create a URL for the selected file
      const fileUrl = URL.createObjectURL(files[0]);
      if (name === 'image') {
        setImagePreviewUrl(fileUrl);
      } else if (name === 'video') {
        setVideoPreviewUrl(fileUrl);
      }
    } else {
      setCasetData({ ...casetData, [name]: value });
    }
  };

  return (
    <div>
      <HeaderComponent />
      <ToastContainer position="top-center" autoClose={5000} />
      <main className="container my-5">
        <section className="row justify-content-center">
          <div className="col-lg-8">
            <h1 className="display-4 mb-4 text-light">
              {casetId ? 'Edit ' : 'Create '}
              {' '}
              Caset
            </h1>
            <form onSubmit={handleSubmit} className="text-light">
              <div className="mb-3">
                <label htmlFor="titleInput" className="form-label text-light">Title</label>
                <input
                  required
                  placeholder="Example Title"
                  type="text"
                  className="form-control text-light bg-dark"
                  id="titleInput"
                  name="title"
                  value={casetData.title}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="descriptionInput" className="form-label text-light">Description</label>
                <textarea
                  required
                  placeholder="Example Description"
                  className="form-control text-light bg-dark"
                  id="descriptionInput"
                  name="description"
                  value={casetData.description}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="priceInput" className="form-label">
                  Price ($)
                  <select
                    className="form-control text-light bg-dark border-secondary"
                    id="priceInput"
                    name="price"
                    value={casetData.price}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Price</option>
                    <option value="1.99">$1.99</option>
                    <option value="2.99">$2.99</option>
                    <option value="3.99">$3.99</option>
                    <option value="4.99">$4.99</option>
                    <option value="5.99">$5.99</option>
                    <option value="6.99">$6.99</option>
                    <option value="7.99">$7.99</option>
                    <option value="8.99">$8.99</option>
                    <option value="9.99">$9.99</option>
                  </select>

                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="imageInput" className="form-label text-light">Preview Image</label>
                <input
                  type="file"
                  className="form-control text-light bg-dark"
                  id="imageInput"
                  name="image"
                  onChange={handleChange}
                  accept="image/*"
                />
                {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', height: 'auto' }} />}
              </div>
              <div className="mb-3">
                <label htmlFor="videoInput" className="form-label text-light">Content Video</label>
                <input
                  type="file"
                  className="form-control text-light bg-dark"
                  id="videoInput"
                  name="video"
                  onChange={handleChange}
                  accept="video/*"
                />
                {videoPreviewUrl && <video src={videoPreviewUrl} style={{ maxWidth: '200px', height: 'auto' }} controls />}
              </div>
              {(casetData.status === 'pending' || !casetData.status)
              && (
              <div>
                <button type="submit" className="btn btn-primary me-2" disabled={uploading}>
                  {casetId ? 'Update ' : 'Create '}
                  Caset
                </button>
                <button type="button" className="btn btn-primary" onClick={() => setShowArchiveModal(true)} disabled={uploading}>
                  Archive Caset
                </button>
              </div>
              )}
              {uploading && (
              <div>
                Uploading:
                {' '}
                {uploadProgress.toFixed(2)}
                %
              </div>
              )}
            </form>
          </div>
        </section>
      </main>
      <div className={`modal fade ${showArchiveModal ? 'show' : ''}`} style={{ display: showArchiveModal ? 'block' : 'none' }} id="archiveModal" tabIndex="-1" aria-labelledby="archiveModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="archiveModalLabel">Archive Caset</h5>
              <button type="button" className="btn-close" onClick={() => setShowArchiveModal(false)} aria-label="Close" />
            </div>
            <div className="modal-body">
              Are you sure you want to archive this caset?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowArchiveModal(false)}>Cancel</button>
              <button type="button" className="btn btn-primary" onClick={handleArchive}>Archive</button>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default EditCaset;
