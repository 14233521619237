import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ToastContainer, toast } from 'react-toastify';
import { db } from '../utils/firebase';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';
import useAuth from '../hooks/useAuth';

function Caset() {
  const { casetId } = useParams();
  const [casetData, setCasetData] = useState(null);
  const [contentUrl, setContentUrl] = useState(null);
  const [hasPurchased, setHasPurchased] = useState(false);
  const [isArtist, setIsArtist] = useState(false);
  const [loading, setLoading] = useState(false);
  const { signInWithGoogle, user } = useAuth();
  const functions = getFunctions();

  const fetchContentUrl = async () => {
    if (hasPurchased) {
      const getInternalContentLink = httpsCallable(functions, 'getInternalContentLink');
      try {
        const result = await getInternalContentLink({ casetId });
        setContentUrl(result.data.internalContentLink);
      } catch (error) {
        toast.error('Error fetching content.');
      }
    }
  };

  const logView = async () => {
    if (!casetId) {
      return;
    }

    if (sessionStorage.getItem(`viewed-${casetId}`)) {
      return;
    }

    // Call the Cloud Function to log the view
    const logCasetViewFunction = httpsCallable(functions, 'logCasetView');
    try {
      await logCasetViewFunction({ casetId });
      sessionStorage.setItem(`viewed-${casetId}`, 'true'); // Mark as viewed in this session
    } catch (error) {
      console.error('Error logging view:', error);
    }
  };

  const fetchCaset = async () => {
    if (casetId) {
      try {
        const casetRef = doc(db, 'caset', casetId);
        const casetSnap = await getDoc(casetRef);

        if (casetSnap.exists()) {
          const data = casetSnap.data();
          setCasetData(data);
          setIsArtist(user && data.artistId === user.uid); // Check if the user is the artist
        } else {
          console.error('Caset not found');
        }
      } catch (error) {
        console.error('Error fetching caset:', error);
      }
    }
  };

  useEffect(() => {
    if (hasPurchased) {
      fetchContentUrl();
    }
  }, [hasPurchased]);

  useEffect(() => {
    logView();
  }, [casetId]);

  useEffect(() => {
    fetchCaset();
    // Real-time listener for user's purchases
    let unsubscribe = () => {};

    if (user) {
      const userPrivateRef = doc(db, 'userPrivate', user.uid);

      unsubscribe = onSnapshot(userPrivateRef, (userDoc) => {
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.purchasedCasetIds && userData.purchasedCasetIds.includes(casetId)) {
            setHasPurchased(true);
            toast.success('Purchase confirmed!');
          }
        }
      });
    }

    // Cleanup listener when component unmounts
    return () => unsubscribe();
  }, [casetId, user]);

  const renderContent = () => {
    if (!contentUrl) return null;

    // switch (casetData.contentType) {
    //   case 'audio':
    //     return (
    //       <audio controls src={contentUrl}>
    //         <track kind="captions" />
    //         Your browser does not support the audio element.
    //       </audio>
    //     );
    //   case 'video':
    return (
      <video
        controls
        src={contentUrl}
        style={{
          maxHeight: '70vh',
          width: '100%',
          objectFit: 'fit',
          borderRadius: '16px', // Set border radius to 16px
        }}
        controlsList="nodownload"
      >
        <track kind="captions" />
        Your browser does not support the video element.
      </video>

    );
    //   case 'image':
    //     return <img src={contentUrl} alt={casetData.title} className="img-fluid" />;
    //   default:
    //     return <p>Unsupported content type</p>;
    // }
  };

  const handlePurchase = async () => {
    if (!user) {
      // User is not logged in, trigger Google Sign-In
      toast.info('Please log in to make a purchase');
      try {
        await signInWithGoogle();
        // Continue with the purchase process after successful sign-in
      } catch (error) {
        console.error('Error signing in:', error);
        toast.error('Error during sign-in.');
        return; // Exit the function if sign-in fails
      }
    }

    setLoading(true); // Start loading

    // User is logged in, proceed with purchase
    const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');

    createCheckoutSession({ casetId })
      .then((result) => {
        // Redirect to Stripe Payment Link
        toast.info('Redirecting to payment...');
        const { url } = result.data;
        window.location.href = url;
      })
      .catch((error) => {
        setLoading(false); // Stop loading in case of error
        console.error('Error creating payment link:', error);
        toast.error('Error initiating payment process.');
      });
  };

  if (!casetData) {
    return <div>Loading...</div>; // Or any loading state you prefer
  }

  return (
    <div>
      <HeaderComponent />
      <ToastContainer position="top-center" autoClose={5000} />
      <main className="container my-5">
        <section className="row justify-content-center">
          <div className="col-lg-12">
            <p className="text-light">
              {casetData.status.charAt(0).toUpperCase() + casetData.status.slice(1)}
            </p>

            <h1 className="display-4 mb-4 text-light">{casetData.title}</h1>
            <p className="text-light mt-3">{casetData.description}</p>
            <p className="text-light">
              Price: $
              {casetData.price}
            </p>
            {!hasPurchased && casetData.status === 'approved' && !isArtist && (
              loading
                ? <div>Loading...</div> // Display loading indicator when loading
                : (
                  <button type="button" className="btn btn-primary mb-3" onClick={handlePurchase}>
                    Purchase
                  </button>
                ) // Display the button when not loading
            )}

            {!hasPurchased
              ? (
                <img
                  src={casetData.previewImage}
                  alt={casetData.title}
                  className="img-fluid"
                  style={{
                    height: '75vh', width: '100%', objectFit: 'cover', borderRadius: '16px',
                  }}
                />
              )
              : renderContent()}
          </div>

        </section>
      </main>
      <FooterComponent />
    </div>
  );
}

export default Caset;
