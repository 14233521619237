/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  getStorage, ref, uploadBytes, getDownloadURL,
} from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../utils/firebase';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';
import useAuth from '../hooks/useAuth';

function Account() {
  const { user, userPrivateData, isArtist } = useAuth(); // Use data from the useAuth hook
  const location = useLocation();
  const [userData, setUserData] = useState({ username: '', name: '', profileImage: '' });
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [stripeStatus, setStripeStatus] = useState('');

  useEffect(() => {
    // Update userData when userPrivateData changes
    if (userPrivateData) {
      setUserData((prevData) => ({
        ...prevData,
        ...userPrivateData,
      }));
    }
  }, [userPrivateData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const stripeReturn = searchParams.get('stripeReturn');
    const stripeRefresh = searchParams.get('stripeRefresh');
    const functions = getFunctions();

    if (user && user.uid && stripeReturn) {
      const stripeReturnFunction = httpsCallable(functions, 'verifyStripe');

      stripeReturnFunction().then(() => {
        // Handle result here
        setStripeStatus('Stripe setup completed successfully.');
      }).catch((error) => {
        // Handle error
        setStripeStatus('Error in Stripe setup. ', error);
      });
    }

    if (stripeRefresh) {
      const stripeRefreshFunction = httpsCallable(functions, 'stripeRefresh');

      stripeRefreshFunction().then(() => {
        // Handle result here
        setStripeStatus('Stripe now ready, setup your account now.');
      }).catch((error) => {
        // Handle error
        console.log('error from stripe: ', error);
        setStripeStatus('Error in Stripe refresh, try again.');
      });
    }
  }, [user, location]);

  useEffect(() => {
    if (user && user.uid) {
      const userRef = doc(db, 'users', user.uid);

      // Fetch user data from Firestore
      getDoc(userRef).then((response) => {
        if (response.exists()) {
          setUserData({ ...userData, ...response.data() });
        }
      });
    }
  }, [user]); // Dependency on user object

  useEffect(() => {
    console.log('userData is ', userData);
  }, [userData]);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setNewProfileImage(e.target.files[0]);
      const fileUrl = URL.createObjectURL(e.target.files[0]);
      setUserData({ ...userData, profileImage: fileUrl });
    }
  };

  const handleSave = async () => {
    if (user && user.uid) {
      let newImageUrl = userData.profileImage;

      if (newProfileImage) {
        const storage = getStorage();
        const storageRef = ref(storage, `profileImages/${user.uid}`);
        await uploadBytes(storageRef, newProfileImage);
        newImageUrl = await getDownloadURL(storageRef);
      }

      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        ...userData,
        profileImage: newImageUrl,
      });
      setUserData({ ...userData, profileImage: newImageUrl });
    }
  };

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const setupStripe = async () => {
    if (userData.stripeAccountLink) {
      window.open(userData.stripeAccountLink);
    }
  };

  return (
    <div>
      <HeaderComponent />
      <main className="container my-5">
        <section className="row justify-content-center">
          <div className="col-lg-8">
            <h1 className="display-4 mb-4 text-light">Account</h1>
            <form>
              <div className="mb-3">
                <label htmlFor="username" className="form-label text-light">Username</label>
                <input type="text" className="form-control text-light bg-dark" id="username" name="username" value={userData.username} onChange={handleInputChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label text-light">Name</label>
                <input type="text" className="form-control text-light bg-dark" id="name" name="name" value={userData.name} onChange={handleInputChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="profileImage" className="form-label text-light">Profile Image</label>
                <input type="file" className="form-control text-light bg-dark" id="profileImage" onChange={handleImageChange} />
                {userData.profileImage && <img src={userData.profileImage} alt="Profile" style={{ width: '100px', height: '100px' }} />}
              </div>
              <div>
                <button type="button" className="btn btn-primary me-2" onClick={handleSave}>Save Changes</button>
                {isArtist && (
                <div className="d-inline-block">
                  {stripeStatus && <div>{stripeStatus}</div>}
                  {userData.isStripeSetupComplete ? (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => window.open('https://dashboard.stripe.com/')}
                    >
                      View Stripe Account
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={setupStripe}
                    >
                      Setup Artist Payouts
                    </button>
                  )}
                </div>
                )}
              </div>

            </form>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
}

export default Account;
