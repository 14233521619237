import React from 'react';
import { Routes, Route } from 'react-router-dom';

import LandingPage from './pages/landing';
import HomePage from './pages/home';
import AccountPage from './pages/account';
import EditPage from './pages/edit';
import CasetPage from './pages/caset';
import Terms from './pages/terms';
import Privacy from './pages/privacy';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/edit/:casetId?" element={<EditPage />} />
      <Route path="/c/:casetId?" element={<CasetPage />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  );
}

export default AppRoutes;
