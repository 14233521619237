import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';
import useAuth from '../hooks/useAuth';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';

function Home() {
  const [username, setUsername] = useState('');
  const [createdCasets, setCreatedCasets] = useState([]);
  const [purchasedCasets, setPurchasedCasets] = useState([]);
  const { user, isArtist, userPrivateData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setUsername('');
  }, [user]);

  const fetchCasetsData = async () => {
    if (!userPrivateData) {
      return;
    }

    const fetchCasetData = async (id) => {
      const casetRef = doc(db, 'caset', id);
      const casetSnap = await getDoc(casetRef);

      const casetPrivateRef = doc(db, 'casetPrivate', id);
      const casetPrivateSnap = await getDoc(casetPrivateRef);

      if (casetSnap.exists() && casetPrivateSnap.exists()) {
        return {
          id: casetSnap.id,
          ...casetSnap.data(),
          viewCount: casetPrivateSnap.data().viewCount || 0,
        };
      }
      return null;
    };

    const createdCasetsData = await Promise.all(
      userPrivateData.createdCasetIds.map(fetchCasetData),
    );
    setCreatedCasets(createdCasetsData.filter((caset) => caset !== null));

    const purchasedCasetsData = await Promise.all(
      userPrivateData.purchasedCasetIds.map(fetchCasetData),
    );
    setPurchasedCasets(purchasedCasetsData.filter((caset) => caset !== null));
  };

  useEffect(() => {
    fetchCasetsData();
  }, [userPrivateData]);

  const handleEdit = (casetId) => {
    navigate(`/edit/${casetId}`);
  };

  const handleView = (casetId) => {
    navigate(`/c/${casetId}`);
  };

  const renderCasets = (casets, showEdit) => casets.map((caset) => (
    <div key={caset.id} className="card bg-dark border border-light" style={{ width: '18rem' }}>
      <div className="card-img-top">
        <img
          src={caset.previewImage}
          className="img-fluid mx-auto d-block" // Added my-3 for vertical margin
          alt={caset.title}
          style={{ height: '200px', objectFit: 'cover', width: '100%' }}
        />
      </div>
      <div className="card-body">
        <h5 className="card-title text-light">
          Title:
          {' '}
          {caset.title}
        </h5>
        <p className="card-text text-light">
          Description:
          {' '}
          {caset.description}
        </p>
        <p className="card-text text-light">
          Price:
          {' $'}
          {caset.price}
        </p>
        <p className="card-text text-light">
          Views:
          {' '}
          {caset.viewCount}
        </p>
        <p className="card-text text-light">
          Status:
          {' '}
          {caset.status ?? 'Pending'}
        </p>
        <div>
          {showEdit && (
          <button
            type="button"
            className="btn btn-primary me-2"
            onClick={() => handleEdit(caset.id)}
          >
            Edit
          </button>
          )}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => handleView(caset.id)}
          >
            View
          </button>
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <HeaderComponent />
      <main className="container my-5">
        <section className="my-5">
          <h1 className="display-4 mb-4 text-light text-center">
            Welcome
            {' '}
            {username}
          </h1>
          <h2>Purchased Casets</h2>
          <div className="scroller">
            {purchasedCasets.length > 0 ? renderCasets(purchasedCasets, false) : <p className="text-light">You have not purchased any Casets yet.</p>}
          </div>
          {isArtist
          && (
          <>
            <h2>Created Casets</h2>
            <div className="scroller">
              {createdCasets.length > 0 ? renderCasets(createdCasets, true) : <p className="text-light">You have not created any Casets yet.</p>}
            </div>
          </>
          )}
        </section>
      </main>
      <FooterComponent />
    </div>
  );
}

export default Home;
